<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{ $t("Manage Sectors") }}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{sectors.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem" v-if="$checkPermission('AdminMenu.Read')">{{$t("newsector")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t(formTitle)}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.LookUpStringE" :rules="[rules.required]" outlined dense :label="$t('Sector in English')" :placeholder="$t('Sector in English')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.LookUpStringF" :rules="[rules.required]" outlined dense :label="$t('Sector in French')" :placeholder="$t('Sector in French')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.LookUpKey" :rules="[rules.required]" outlined dense :label="$t('abbreviation')" :placeholder="$t('abbreviation')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="editedItem.LookUpOther"
                        :search-input.sync="additional.to"
                        dense
                        persistent-hint
                        :label="$t('to')"
                        :placeholder="$t('to')"
                        outlined
                        class="white pa-0 my-3"
                        small-chips
                        return-object 
                        :rules="[rules.requiredmultiple]"
                        @change="validateInput"
                        multiple>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{$t("No results matching")}} "<strong>{{ additional.to }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="editedItem.LookUpFileName1"
                        :search-input.sync="additional.cc"
                        dense
                        persistent-hint
                        :label="$t('cc')"
                        :placeholder="$t('cc')"
                        outlined
                        class="white pa-0 my-3"
                        small-chips
                        return-object 
                        @change="validateInput"
                        multiple>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{$t("No results matching")}} "<strong>{{ additional.cc }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="editedItem.LookUpFileName2"
                        :search-input.sync="additional.bcc"
                        dense
                        persistent-hint
                        :label="$t('bcc')"
                        :placeholder="$t('bcc')"
                        outlined
                        class="white pa-0 my-3"
                        small-chips
                        return-object 
                        @change="validateInput"
                        multiple>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{$t("No results matching")}} "<strong>{{ additional.bcc }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this configuration?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          v-if="configurations"
          :headers="headers"
          :items="sectors"
          sort-by="id"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading" 
          :loading-text="$t('Loading')"
          :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.LookUpOther`]="{ item }">
          <div  v-if="item.LookUpOther"><v-chip v-for="email in JSON.parse((item.LookUpOther))" :key="email" small class="ma-1">{{email}}</v-chip></div>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.LookUpFileName1`]="{ item }">
          <div  v-if="item.LookUpFileName1"><v-chip v-for="email in JSON.parse((item.LookUpFileName1))" :key="email" small class="ma-1">{{email}}</v-chip></div>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.LookUpFileName2`]="{ item }">
          <div  v-if="item.LookUpFileName2"><v-chip v-for="email in JSON.parse((item.LookUpFileName2))" :key="email" small class="ma-1">{{email}}</v-chip></div>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
export default {
  name: "Sectors",
  data(){
    return {
      additional: {to:'',cc:'',bcc:''},
      valid: true,
      rules: {
        required: v => !!(v ) || this.$t("required"),
        requiredmultiple: v => !!(v && v.length) || this.$t("required"),
      },
      LookUpName:'',
      editFlag: false,
      deletedItem:"",
      editedItem:{},
      editedIndex: 0,
      headers: [
        {
          text:  this.$t("ID"),
          align: "left",
          sortable: true,
          value: "LookUpTableId"
        },
        { text: this.$t("nameinenglish"), value: "LookUpStringE"},
        { text: this.$t("nameinfrench"), value: "LookUpStringF"},
        { text: this.$t("abbreviation"), value: "LookUpKey" },
        { text: this.$t("to"), value: "LookUpOther" },
        { text: this.$t("cc"), value: "LookUpFileName1" },
        { text: this.$t("bcc"), value: "LookUpFileName2" },
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      dialogDelete: false,
      sectors: [],
      search: {
        searchword:'',
      },
    }
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration',
      formTitle() {
        return this.editedIndex === 0 ? "newfield" : "editfield";
      }
    })
  },
  async mounted(){
    await this.getSectors();
  },
  watch: {
    requestConfiguration(val){
        Object.assign(this.editedItem,val)
      if(val.LookUpName){
        this.editedItem.LookUpOther=JSON.parse(this.editedItem.LookUpOther);
        this.editedItem.LookUpFileName1=JSON.parse(this.editedItem.LookUpFileName1);
        this.editedItem.LookUpFileName2=JSON.parse(this.editedItem.LookUpFileName2);
      }
    }
  },
  methods:{
    ...mapActions('configuration',['addConfiguration', 'updateConfiguration','setConfigurationItem','setFlagEdit', 'removeConfiguration', 'emptyConfiguration']),
    validateInput(item){
      if(item.length){
          let lastEle = item.pop();
          let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
          if(regex.exec(lastEle)){
            item.push(lastEle);
          }
      }
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    getSectors(){
      this.sectors=this.configurations.filter( filter => filter.LookUpName === 'SManager' );
    },
    save(){
      let conf={};
      Object.assign(conf,this.editedItem);
      conf.LookUpName = 'SManager';
        conf.LookUpOther=JSON.stringify(conf.LookUpOther);
        conf.LookUpFileName1=JSON.stringify(conf.LookUpFileName1);
        conf.LookUpFileName2=JSON.stringify(conf.LookUpFileName2);
      if(this.editFlag){
         if(this.$refs.form.validate()){
          this.updateConfiguration(conf).then(()=>{
            this.getSectors();
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            this.editFlag = false;
          })
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.addConfiguration(conf).then(()=>{
            this.getSectors();
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
          
        }
        else
          return
      }
      this.dialog = false;
    },
    editItem(item){
        this.dialog = true;
        this.editedIndex = -1;
        this.editFlag = true;
        this.setConfigurationItem(item);
    },
    addItem(){
      this.dialog = true;
      this.editedIndex = 0;
      this.editFlag = false;
      this.emptyConfiguration();
    },
    deleteItem(item){
        this.deletedItem = item;
        this.dialogDelete = true;
    },
    async deleteConfig(){
      this.removeConfiguration( this.deletedItem.LookUpTableId ).then(()=>{
          this.dialogDelete = false;
          this.getSectors();
          let color="dark";
          let alert="itemRemovedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
    close() {
      this.loading = false;
    },
  }

}
</script>

<style scoped>

</style>