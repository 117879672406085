<template>
  <div v-if="configurations.length>0">
    <sector-manager></sector-manager>
  </div>
</template>

<script>
import { mapState} from "vuex";
import SectorManagerVue from '../components/admin/SectorManager.vue';

export default {
  name: "Sectors",
  components: {
    'sector-manager': SectorManagerVue
  },
  async beforeCreate() {
    await this.$store.dispatch("configuration/retriveConfigurations");
  },

  data(){
    return {
    };
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    })
  }
}
</script>

<style scoped>

</style>